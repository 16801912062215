import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "schedule-station";
const useScheduleStation = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (scheduleStationId) => {
      return state.items.filter((item) => item.id === scheduleStationId);
    },
  },
});

api.service(servicePath).hooks({});

export { useScheduleStation };
