import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "udi-prefix";
const useUdiPrefix = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (udiPrefixId) => {
      return state.items.filter((item) => item.id === udiPrefixId);
    },
  },
});

api.service(servicePath).hooks({});

export { useUdiPrefix };
