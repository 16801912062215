<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { useScheduleStation } from "../../store/scheduleStation.pinia";
import { useScheduleGroup } from "../../store/scheduleGroup.pinia";
import { useUdiPrefix } from "../../store/udiPrefix.pinia";
import {
  useFindByPartId,
  useGetHelper,
} from "../../utils/feathers-pinia/use-get-helpers";
import { usePartSchedule } from "../../store/partSchedule.pinia";
import { useScheduleDocument } from "../../store/scheduleDocument.pinia";
import { useFind } from "feathers-pinia";
import { useDocument } from "../../store/document.pinia";
import { computedRef } from "../../utils/composables";

export default {
  name: "FinishedGoodsInitAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("FinishedGoodsInitAccordion.vue");
    const partScheduleStore = usePartSchedule();
    const scheduleStationStore = useScheduleStation();
    const scheduleGroupStore = useScheduleGroup();
    const udiPrefixStore = useUdiPrefix();
    const scheduleDocumentStore = useScheduleDocument();
    const documentStore = useDocument();

    const partId = computed(() => props?.node?.part?.id ?? null);
    const { items: partScheduleResult, isPending: isPendingPartSchedule } =
      useFindByPartId(partId, partScheduleStore);

    const scheduleStationId = computed(
      () => partScheduleResult?.value[0]?.schedule_station_id ?? null
    );
    const { item: scheduleStationResult, isPending: isPendingScheduleStation } =
      useGetHelper(scheduleStationId, scheduleStationStore);

    const scheduleGroupId = computed(
      () => partScheduleResult?.value[0]?.schedule_group_id ?? null
    );
    const { item: scheduleGroupResult, isPending: isPendingScheduleGroup } =
      useGetHelper(scheduleGroupId, scheduleGroupStore);

    const udiPrefixId = computed(
      () => partScheduleResult?.value[0]?.udi_prefix_id ?? null
    );
    const { item: udiPrefixResult, isPending: isPendingUdiPrefix } =
      useGetHelper(udiPrefixId, udiPrefixStore);

    const {
      items: scheduleDocumentResults,
      isPending: isPendingScheduleDocument,
    } = useFindByPartId(props.node?.part?.id, scheduleDocumentStore);

    const documentIds = computedRef(() => {
      return (
        scheduleDocumentResults.value?.map((item) => item.document_id) ?? []
      );
    });
    const documentQuery = computedRef(() => {
      return {
        query: {
          id: { $in: documentIds.value },
          $sort: { name: 1 },
          class: { $in: ["Schedule", "Packing"] },
        },
      };
    });
    const { items: documentResults, isPending: isPendingDocument } = useFind({
      model: documentStore.Model,
      params: documentQuery,
      queryWhen: computed(() => !!documentQuery.value?.query.id.$in.length),
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        udiPrefix: isPendingUdiPrefix.value,
        scheduleStation: isPendingScheduleStation.value,
        scheduleGroup: isPendingScheduleGroup.value,
        partSchedule: isPendingPartSchedule.value,
        document: isPendingDocument.value,
        scheduleDocument: isPendingScheduleDocument.value,
      };
    });

    const entities = computed(() => {
      return {
        part: props.node.part,
        udiPrefix: udiPrefixResult.value,
        scheduleStation: scheduleStationResult.value,
        scheduleGroup: scheduleGroupResult.value,
        partSchedule: partScheduleResult?.value[0],
        document: documentResults.value,
        scheduleDocument: scheduleDocumentResults.value,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow("Finished Goods Initiation", [
          entityRowField("scheduleStation", "description", {
            label: "Schedule Station",
          }),
          entityRowField("scheduleGroup", "description", {
            label: "Schedule Group",
          }),
          entityRowField("partSchedule", "icon", {
            label: "Icon",
          }),
          entityRowField("part", "fdi_pick_list", {
            label: "FDI Picklist",
            type: "checkbox",
          }),
          entityRowField("udiPrefix", "udi_prefix", {
            label: "UDI Prefix",
          }),
          entityRowField("partSchedule", "udi", {
            label: "Part Schedule Udi",
          }),
        ]),
        entityRow(
          "Documents",
          [
            entityRowField("scheduleDocument", null, {
              includeEntities: true,
              type: "documents",
            }),
          ],
          {
            showLabel: true,
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
