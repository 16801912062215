import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "part-schedule";
const usePartSchedule = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (partScheduleId) => {
      return state.items.filter((item) => item.id === partScheduleId);
    },
  },
});

api.service(servicePath).hooks({});

export { usePartSchedule };
